
import notification from "@/services/notificationService";
import Vue from "vue";
import api from "../services/dataService";
// import VueJsonPretty from "vue-json-pretty";
import { DateTime } from "luxon";
import "vue-json-pretty/lib/styles.css";
import paginate from "vuejs-paginate";

export default Vue.extend({
  name: "DongleEvents",
  components: {
    // VueJsonPretty
    paginate
  },
  data() {
    return {
      DateTime,
      showDataSummary: false,
      dongleEvents: undefined,
      timezone: "LOCAL" as "LOCAL" | "GMT",
      selected: undefined,
      dongleId: undefined,
      date: undefined,
      tableProps: {
        loading: false,
        total: 0,
        page: 1,
        perPage: 2000,
        totalPages: 0
      }
    };
  },
  computed: {
    tableData() {
      return [
        "payloadId",
        "eventType",
        "recordedAt",
        "receivedAt",
        "createdAt",
        "LONGITUDE",
        "LATITUDE",
        "GPS_SPEED",
        "GPS_DIR",
        "MDI_JOURNEY_STATE",
        "DIO_IGNITION",
        "MDI_IDLE_STATE",
        "BATT",
        "MDI_EXT_BATT_PRESENT",
        "EVENT",
        "BOOT_REASON",
        "ODO_FULL",
        "GPS_PDOP",
        "OBD_CONNECTED_PROTOCOL",
        "MDI_LAST_VALID_GPS_LATITUDE",
        "MDI_LAST_VALID_GPS_LONGITUDE",
        "SHUTDOWN_TYPE_AND_REASON",
        "MDI_OBD_ENGINE_OIL_TEMP",
        "MDI_OBD_ENGINE_COOLANT_TEMP",
        "BEHAVE_ID",
        "BEHAVE_GPS_SPEED_BEGIN",
        "BEHAVE_GPS_SPEED_PEAK",
        "BEHAVE_GPS_SPEED_END",
        "BEHAVE_GPS_HEADING_BEGIN",
        "BEHAVE_GPS_HEADING_PEAK",
        "BEHAVE_GPS_HEADING_END",
        "BEHAVE_ACC_X_BEGIN",
        "BEHAVE_ACC_X_PEAK",
        "BEHAVE_ACC_X_END",
        "BEHAVE_ACC_Y_BEGIN",
        "BEHAVE_ACC_Y_PEAK",
        "BEHAVE_ACC_Y_END",
        "BEHAVE_ACC_Z_BEGIN",
        "BEHAVE_ACC_Z_PEAK",
        "BEHAVE_ACC_Z_END",
        "BEHAVE_ELAPSED",
        "BEHAVE_UNIQUE_ID",
        "MDI_OBD_VIN_ALT",
        "MDI_EXT_BATT_LOW",
        "MDI_EXT_BATT_VOLTAGE",
        "MDI_DTC_LIST",
        "ODO_FULL_METER",
        "MDI_FUEL_TYPE",
        "MDI_JOURNEY_ID",
        "MDI_DASHBOARD_MILEAGE",
        "MDI_DASHBOARD_FUEL_LEVEL",
        "MDI_OBD_SQUISH_VIN",
        "MDI_PENDING_DTC_LIST",
        "MDI_OBD_MILEAGE_METERS",
        "MDI_VEHICLE_STATE",
        "MDI_OBD_SPEED",
        "MDI_OBD_FUEL",
        "MDI_OBD_VIN",
        "MDI_OBD_MILEAGE",
        "MDI_JOURNEY_TIME",
        "MDI_IDLE_JOURNEY",
        "MDI_DRIVING_JOURNEY",
        "MDI_MAX_SPEED_IN_LAST_OVERSPEED",
        "MDI_OVERSPEED_COUNTER",
        "MDI_TOW_AWAY",
        "MDI_ODO_JOURNEY",
        "MDI_OVERSPEED",
        "MDI_OBD_AMBIENT_AIR_TEMPERATURE",
        "MDI_EXT_VOLT_SNAPSHOT",
        "TRIP_DETECTOR_STATUS",
        "MDI_OBD_BAROMETRIC_PRESSURE",
        "MDI_OBD_DISTANCE_SINCE_DTC_CLEARED",
        "MDI_OBD_DISTANCE_TRAVELLED_WHILE_MIL_ACTIVATED",
        "MDI_OBD_CO2_EMISSIONS",
        "MDI_UNPLUG_DURATION",
        "MDI_UNPLUG_TIMESTAMP",
        "MDI_OBD_HEV_BATTERY_VOLTAGE",
        "MDI_OBD_HEV_BATTERY_CURRENT",
        "MDI_OBD_HEV_BATTERY_STATE_OF_CHARGE",
        "MDI_SERIAL_NUMBER",
        "MDI_SOFTWARE_VERSION",
        "MDI_DTC_MIL",
        "MDI_DTC_NUMBER"
      ];
    }
  },
  watch: {
    timezone: function() {
      this.$router.replace({
        query: {
          ...this.$route.query,
          timezone: this.timezone
        }
      });
    }
  },
  created() {
    if (this.$route.query.timezone) {
      this.timezone = this.$route.query.timezone.toString();
    }
    if (this.$route.query.date) {
      this.date = new Date(this.$route.query.date.toString());
    }
    if (this.$route.query.page) {
      this.tableProps.page = parseInt(this.$route.query.page.toString());
    }
    if (this.$route.query.dongleId) {
      this.dongleId = parseInt(this.$route.query.dongleId.toString());
    }
  },
  methods: {
    getDongleEvents() {
      this.dongleEvents = undefined;
      this.$router.replace({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          date: DateTime.fromISO(this.date.toISOString()).toISO({
            includeOffset: false
          }),
          dongleId: this.dongleId,
          page: this.tableProps.page
        }
      });
      this.tableProps.loading = true;
      const type = "decoded";
      api
        .get("/dongle-events/" + this.dongleId + "/" + type, {
          params: {
            date: DateTime.fromISO(this.date.toISOString()).toISO({
              includeOffset: false
            }),
            page: this.tableProps.page,
            limit: this.tableProps.perPage
          }
        })
        .then(res => {
          this.dongleEvents = res.data;
          if (res.data) {
            this.tableProps.total = res.data.totalItems;
            this.tableProps.data = res.data.items;
            this.tableProps.loading = false;
            this.tableProps.totalPages = Math.ceil(
              res.data.totalItems / this.tableProps.perPage
            );
          }
        })
        .catch(err => {
          this.tableProps.loading = false;
          notification.error(err.data.message);
        });
    }
  }
});
